import { templateTypes } from '@/util/schemas/workflows';

export const lineItemConditions = {
  PRODUCT_TYPE: 'lineItem.product_type',
  PRODUCT_VENDOR: 'lineItem.vendor',
  PRODUCT_ID: 'lineItem.product_id',
  PRODUCT_SKU: 'lineItem.sku',
  PRODUCT_TAGS: 'lineItem.tags',
  PRODUCT_TAGS_PURCHASE: 'lineItem.tags_purchase',
  CUSTOMER_COHORT: 'customer.cohort',
  RETURN_REASON: 'lineItem.reason.id',
};

export const orderConditions = {
  ORDER_TAG: 'order.tags',
  ORDER_DATE: 'order.date',
  ORDER_DISCOUNT: 'order.discount_codes',
  ORDER_DISCOUNT_TOTAL: 'order.discounts_total',
  ORDER_DISCOUNT_PERCENTAGE: 'order.discount_percentage',
  ORDER_TOTAL_VALUE: 'order.total',
  ORDER_COUNTRY: 'order.country',
  ORDER_RETURN_COUNT: 'order.return_count',
  ORDER_DAYS_SINCE_FULFILLED_COUNT: 'order.days_since_fulfilled_count',
  ORDER_DAYS_SINCE_RETURN_WINDOW_START_COUNT: 'order.days_since_return_window_start_count',
};

export const customerConditions = {
  CUSTOMER_TAG: 'customer.tags',
  CUSTOMER_NUMBER_OF_ORDERS: 'customer.order_count',
  CUSTOMER_RETURN_COUNT: 'customer.returns',
  CUSTOMER_RETURN_COUNT_ALL_TIME: 'customer.returns.all_time',
  CUSTOMER_RETURN_COUNT_MONTH_ONE: 'customer.returns.month_1',
  CUSTOMER_RETURN_COUNT_MONTH_THREE: 'customer.returns.month_3',
  CUSTOMER_RETURN_COUNT_MONTH_SIX: 'customer.returns.month_6',
  CUSTOMER_RETURN_COUNT_MONTH_TWELVE: 'customer.returns.month_12',
  CUSTOMER_RETURN_COUNT_MONTH_TWENTY_FOUR: 'customer.returns.month_24',
  CUSTOMER_KEEP_COUNT: 'customer.keep_items',
  CUSTOMER_KEEP_COUNT_ALL_TIME: 'customer.keep_items.all_time',
  CUSTOMER_KEEP_COUNT_MONTH_ONE: 'customer.keep_items.month_1',
  CUSTOMER_KEEP_COUNT_MONTH_THREE: 'customer.keep_items.month_3',
  CUSTOMER_KEEP_COUNT_MONTH_SIX: 'customer.keep_items.month_6',
  CUSTOMER_KEEP_COUNT_MONTH_TWELVE: 'customer.keep_items.month_12',
  CUSTOMER_KEEP_COUNT_MONTH_TWENTY_FOUR: 'customer.keep_items.month_24',
  CUSTOMER_BONUS_CREDIT: 'customer.bonus_credit',
  CUSTOMER_BONUS_CREDIT_ALL_TIME: 'customer.bonus_credit.all_time',
  CUSTOMER_BONUS_CREDIT_MONTH_ONE: 'customer.bonus_credit.month_1',
  CUSTOMER_BONUS_CREDIT_MONTH_THREE: 'customer.bonus_credit.month_3',
  CUSTOMER_BONUS_CREDIT_MONTH_SIX: 'customer.bonus_credit.month_6',
  CUSTOMER_BONUS_CREDIT_MONTH_TWELVE: 'customer.bonus_credit.month_12',
  CUSTOMER_BONUS_CREDIT_MONTH_TWENTY_FOUR: 'customer.bonus_credit.month_24',
  CUSTOMER_IX_DECLINED_COUNT: 'customer.ix_charge_declines',
  CUSTOMER_IX_DECLINED_COUNT_ALL_TIME: 'customer.ix_charge_declines.all_time',
  CUSTOMER_IX_DECLINED_COUNT_MONTH_ONE: 'customer.ix_charge_declines.month_1',
  CUSTOMER_IX_DECLINED_COUNT_MONTH_THREE: 'customer.ix_charge_declines.month_3',
  CUSTOMER_IX_DECLINED_COUNT_MONTH_SIX: 'customer.ix_charge_declines.month_6',
  CUSTOMER_IX_DECLINED_COUNT_MONTH_TWELVE: 'customer.ix_charge_declines.month_12',
  CUSTOMER_IX_DECLINED_COUNT_MONTH_TWENTY_FOUR: 'customer.ix_charge_declines.month_24',
  CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT: 'customer.partial_return_credit_limit',
  CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT_ALL_TIME: 'customer.partial_return_credit_limit.all_time',
  CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT_MONTH_ONE: 'customer.partial_return_credit_limit.month_1',
  CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT_MONTH_THREE: 'customer.partial_return_credit_limit.month_3',
  CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT_MONTH_SIX: 'customer.partial_return_credit_limit.month_6',
  CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT_MONTH_TWELVE: 'customer.partial_return_credit_limit.month_12',
  CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT_MONTH_TWENTY_FOUR: 'customer.partial_return_credit_limit.month_24',
  CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS: 'customer.return_count_with_missing_or_incorrect_items',
  CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_ALL_TIME: 'customer.return_count_with_missing_or_incorrect_items.all_time',
  CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_ONE: 'customer.return_count_with_missing_or_incorrect_items.month_1',
  CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_THREE: 'customer.return_count_with_missing_or_incorrect_items.month_3',
  CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_SIX: 'customer.return_count_with_missing_or_incorrect_items.month_6',
  CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_TWELVE: 'customer.return_count_with_missing_or_incorrect_items.month_12',
  CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_TWENTY_FOUR: 'customer.return_count_with_missing_or_incorrect_items.month_24',
};

export const returnConditions = {
  RETURN_TOTAL_VALUE: 'return.total_value',
  RETURN_ITEM_COUNT: 'return.item_count',
  RETURN_OUTCOME: 'return.outcome',
  RETURN_METHOD: 'return.return_method',
  RETURN_PORTAL: 'return.portal',
};

export const fraudConditions = {
  HIGH_FRAUD_RISK: 'fraud.high_risk',
};

export const conditions = {
  ...lineItemConditions,
  ...orderConditions,
  ...customerConditions,
  ...returnConditions,
  ...fraudConditions,
};

export const conditionLabels = {
  [conditions.PRODUCT_TYPE]: 'Product type',
  [conditions.PRODUCT_VENDOR]: 'Product vendor',
  [conditions.PRODUCT_ID]: 'Product ID',
  [conditions.PRODUCT_SKU]: 'Product SKU',
  [conditions.PRODUCT_TAGS]: 'Product tag',
  [conditions.PRODUCT_TAGS_PURCHASE]: 'Product tag',
  [conditions.ORDER_DAYS_SINCE_RETURN_WINDOW_START_COUNT]: 'Order days since return window start',
  [conditions.CUSTOMER_COHORT]: 'Customer cohort',
  [conditions.RETURN_REASON]: 'Product return reason',
  [conditions.ORDER_TAG]: 'Order tag',
  [conditions.ORDER_DATE]: 'Order date',
  [conditions.ORDER_DISCOUNT]: 'Order discount',
  [conditions.ORDER_DISCOUNT_TOTAL]: 'Order discount',
  [conditions.ORDER_DISCOUNT_PERCENTAGE]: 'Order discount',
  [conditions.ORDER_TOTAL_VALUE]: 'Order total value',
  [conditions.ORDER_COUNTRY]: 'Order country',
  [conditions.ORDER_RETURN_COUNT]: 'Order return count',
  [conditions.ORDER_DAYS_SINCE_FULFILLED_COUNT]: 'Order days since fulfilled',
  [conditions.CUSTOMER_TAG]: 'Customer tag',
  [conditions.CUSTOMER_NUMBER_OF_ORDERS]: 'Customer number of orders',
  [conditions.CUSTOMER_RETURN_COUNT]: 'Customer return count',
  [conditions.CUSTOMER_RETURN_COUNT_ALL_TIME]: 'Customer return count',
  [conditions.CUSTOMER_RETURN_COUNT_MONTH_ONE]: 'Customer return count',
  [conditions.CUSTOMER_RETURN_COUNT_MONTH_THREE]: 'Customer return count',
  [conditions.CUSTOMER_RETURN_COUNT_MONTH_SIX]: 'Customer return count',
  [conditions.CUSTOMER_RETURN_COUNT_MONTH_TWELVE]: 'Customer return count',
  [conditions.CUSTOMER_RETURN_COUNT_MONTH_TWENTY_FOUR]: 'Customer return count',
  [conditions.CUSTOMER_KEEP_COUNT]: 'Customer keep items count',
  [conditions.CUSTOMER_KEEP_COUNT_ALL_TIME]: 'Customer keep items count',
  [conditions.CUSTOMER_KEEP_COUNT_MONTH_ONE]: 'Customer keep items count',
  [conditions.CUSTOMER_KEEP_COUNT_MONTH_THREE]: 'Customer keep items count',
  [conditions.CUSTOMER_KEEP_COUNT_MONTH_SIX]: 'Customer keep items count',
  [conditions.CUSTOMER_KEEP_COUNT_MONTH_TWELVE]: 'Customer keep items count',
  [conditions.CUSTOMER_KEEP_COUNT_MONTH_TWENTY_FOUR]: 'Customer keep items count',
  [conditions.CUSTOMER_BONUS_CREDIT]: 'Customer bonus credit total',
  [conditions.CUSTOMER_BONUS_CREDIT_ALL_TIME]: 'Customer bonus credit total',
  [conditions.CUSTOMER_BONUS_CREDIT_MONTH_ONE]: 'Customer bonus credit total',
  [conditions.CUSTOMER_BONUS_CREDIT_MONTH_THREE]: 'Customer bonus credit total',
  [conditions.CUSTOMER_BONUS_CREDIT_MONTH_SIX]: 'Customer bonus credit total',
  [conditions.CUSTOMER_BONUS_CREDIT_MONTH_TWELVE]: 'Customer bonus credit total',
  [conditions.CUSTOMER_BONUS_CREDIT_MONTH_TWENTY_FOUR]: 'Customer bonus credit total',
  [conditions.CUSTOMER_IX_DECLINED_COUNT]: 'Customer failed payments',
  [conditions.CUSTOMER_IX_DECLINED_COUNT_ALL_TIME]: 'Customer failed payments',
  [conditions.CUSTOMER_IX_DECLINED_COUNT_MONTH_ONE]: 'Customer failed payments',
  [conditions.CUSTOMER_IX_DECLINED_COUNT_MONTH_THREE]: 'Customer failed payments',
  [conditions.CUSTOMER_IX_DECLINED_COUNT_MONTH_SIX]: 'Customer failed payments',
  [conditions.CUSTOMER_IX_DECLINED_COUNT_MONTH_TWELVE]: 'Customer failed payments',
  [conditions.CUSTOMER_IX_DECLINED_COUNT_MONTH_TWENTY_FOUR]: 'Customer failed payments',
  [conditions.CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT]: 'Customer partial return credit count',
  [conditions.CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT_ALL_TIME]: 'Customer partial return credit count',
  [conditions.CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT_MONTH_ONE]: 'Customer partial return credit count',
  [conditions.CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT_MONTH_THREE]: 'Customer partial return credit count',
  [conditions.CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT_MONTH_SIX]: 'Customer partial return credit count',
  [conditions.CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT_MONTH_TWELVE]: 'Customer partial return credit count',
  [conditions.CUSTOMER_PARTIAL_RETURN_CREDIT_LIMIT_MONTH_TWENTY_FOUR]: 'Customer partial return credit count',
  [conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS]: 'Customer return count with missing or incorrect items',
  [conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_ALL_TIME]: 'Customer return count with missing or incorrect items',
  [conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_ONE]: 'Customer return count with missing or incorrect items',
  [conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_THREE]: 'Customer return count with missing or incorrect items',
  [conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_SIX]: 'Customer return count with missing or incorrect items',
  [conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_TWELVE]: 'Customer return count with missing or incorrect items',
  [conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_TWENTY_FOUR]: 'Customer return count with missing or incorrect items',
  [conditions.RETURN_TOTAL_VALUE]: 'Return total value',
  [conditions.RETURN_ITEM_COUNT]: 'Return item count',
  [conditions.RETURN_OUTCOME]: 'Return outcome',
  [conditions.RETURN_METHOD]: 'Return method',
  [conditions.HIGH_FRAUD_RISK]: 'Fraud high risk',
  [conditions.RETURN_PORTAL]: 'Return portal',
};

export const conditionDescriptions = {
  [conditions.CUSTOMER_IX_DECLINED_COUNT]: 'Condition is met if the number of failed payment captures for an instant exchange or instant refund meets the specified threshold in the given time period.',
};

export const conditionTypes = {
  RETURN: 'return',
  LINE_ITEM: 'lineItem',
  ORDER: 'order',
  CUSTOMER: 'customer',
  FRAUD: 'fraud',
};

const conditionFieldsToTypesMap = {};

Object.entries(lineItemConditions).forEach(([_, field]) => {
  conditionFieldsToTypesMap[field] = conditionTypes.LINE_ITEM;
});
Object.entries(orderConditions).forEach(([_, field]) => {
  conditionFieldsToTypesMap[field] = conditionTypes.ORDER;
});
Object.entries(customerConditions).forEach(([_, field]) => {
  conditionFieldsToTypesMap[field] = conditionTypes.CUSTOMER;
});
Object.entries(returnConditions).forEach(([_, field]) => {
  conditionFieldsToTypesMap[field] = conditionTypes.RETURN;
});
Object.entries(fraudConditions).forEach(([_, field]) => {
  conditionFieldsToTypesMap[field] = conditionTypes.FRAUD;
});

export const getConditionType = (field) => {
  return conditionFieldsToTypesMap[field] ?? null;
};

export const totalConditions = [
  conditions.ORDER_TOTAL_VALUE,
  conditions.ORDER_DISCOUNT_TOTAL,
  conditions.RETURN_TOTAL_VALUE
];

const excludedAskAQuestionConditions = [
  conditions.RETURN_OUTCOME,
  conditions.RETURN_METHOD,
];

const excludeFromFeatureFlags = {
  customerReturnCountWithIncorrectOrMissingItems: [
    conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS,
    conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_ALL_TIME,
    conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_ONE,
    conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_THREE,
    conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_SIX,
    conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_TWELVE,
    conditions.CUSTOMER_RETURN_COUNT_WITH_MISSING_OR_INCORRECT_ITEMS_MONTH_TWENTY_FOUR,
  ],
  pointOfSaleCondition: [
    conditions.RETURN_PORTAL,
  ],
  fraud: [
    conditions.HIGH_FRAUD_RISK,
  ],
  deliveryReturnWindow: [
    conditions.ORDER_DAYS_SINCE_RETURN_WINDOW_START_COUNT,
  ],
  noDeliveryReturnWindow: [
    conditions.ORDER_DAYS_SINCE_FULFILLED_COUNT,
  ]
};

export const returnPortalOutcomes = {
  CUSTOMER_PORTAL: 'customer-portal',
  POINT_OF_SALE_APP: 'loop-point-of-sale',
};

export const getExcludedConditions = (template, conditionsBehindFeatureFlagsOrSettings) => {
  let excludedConditions = [];

  const excludeConditionsFromFeatureFlagsOrSettings = conditionsBehindFeatureFlagsOrSettings
    .filter(([, flagState]) => !flagState)
    .map(([excludeCondition]) => excludeCondition);

  excludeConditionsFromFeatureFlagsOrSettings.forEach((condition) => {
    excludedConditions = [...excludedConditions, ...excludeFromFeatureFlags[condition]];
  });
  
  if (template === templateTypes.ASK_A_QUESTION) {
    return [ ...excludedConditions, ...excludedAskAQuestionConditions];
  }

  return excludedConditions;
};
