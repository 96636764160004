<template>
  <div class="navigation-header">
    <div class="navigation-header__menu-button">
      <slot name="menubutton">
        <button @click="menuHandler">
          <svg
            v-if="!menuActive"
            class="navigation-header__menu-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line
              x1="3"
              y1="12"
              x2="21"
              y2="12"
            />

            <line
              x1="3"
              y1="6"
              x2="21"
              y2="6"
            />

            <line
              x1="3"
              y1="18"
              x2="21"
              y2="18"
            />
          </svg>

          <svg-icon
            v-else
            name="close"
            class="navigation-header__menu-icon"
          />
        </button>
      </slot>
    </div>

    <div class="navigation-header__main">
      <div class="navigation-header__logo">
        <svg
          width="94"
          height="30"
          viewBox="0 0 94 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.6875 6.54545C10.1908 6.54545 6.54545 10.1908 6.54545 14.6875C6.54545 19.1842 10.1908 22.8295 14.6875 22.8295C19.1842 22.8295 22.8295 19.1842 22.8295 14.6875C22.8295 10.1908 19.1842 6.54545 14.6875 6.54545ZM0 14.6875C0 6.57582 6.57582 0 14.6875 0C22.7992 0 29.375 6.57582 29.375 14.6875C29.375 22.7992 22.7992 29.375 14.6875 29.375C6.57582 29.375 0 22.7992 0 14.6875Z"
            fill="#2B33FF"
          />

          <path
            d="M38.75 24.7222H43.3322V6.25H38.75V24.7222Z"
            fill="white"
          />

          <path
            d="M52.6996 25.1444C58.3474 25.1444 60.3454 21.6611 60.3454 18.125C60.3454 14.5889 58.3474 11.1056 52.6996 11.1056C47.0518 11.1056 45.0804 14.5889 45.0804 18.125C45.0804 21.6875 47.0518 25.1444 52.6996 25.1444ZM49.6626 18.125C49.6626 15.4861 50.5151 14.6417 52.6996 14.6417C54.8841 14.6417 55.7366 15.4861 55.7366 18.125C55.7366 20.7639 54.8841 21.6083 52.6996 21.6083C50.5151 21.6083 49.6626 20.7639 49.6626 18.125Z"
            fill="white"
          />

          <path
            d="M69.1095 25.1444C74.7572 25.1444 76.7553 21.6611 76.7553 18.125C76.7553 14.5889 74.7572 11.1056 69.1095 11.1056C63.4617 11.1056 61.4903 14.5889 61.4903 18.125C61.4903 21.6875 63.4617 25.1444 69.1095 25.1444ZM66.0725 18.125C66.0725 15.4861 66.925 14.6417 69.1095 14.6417C71.294 14.6417 72.1465 15.4861 72.1465 18.125C72.1465 20.7639 71.294 21.6083 69.1095 21.6083C66.925 21.6083 66.0725 20.7639 66.0725 18.125Z"
            fill="white"
          />

          <path
            d="M87.6493 11.1056C85.3849 11.1056 83.8664 11.9236 82.9606 13.3222V11.5278H78.3785V30H82.9606V22.875C83.8664 24.3 85.3849 25.1444 87.6493 25.1444C92.0184 25.1444 93.75 21.8194 93.75 18.1514C93.75 14.3514 92.0184 11.1056 87.6493 11.1056ZM86.1042 21.6083C83.7598 21.6083 82.9606 20.4736 82.9606 18.125C82.9606 15.7764 83.7598 14.6417 86.1042 14.6417C88.3686 14.6417 89.1678 15.6708 89.1678 18.125C89.1678 20.5792 88.3686 21.6083 86.1042 21.6083Z"
            fill="white"
          />
        </svg>
      </div>
    </div>

    <navigation-header-search />

    <navigation-header-mini-nav @menu-click="menuHandler" />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { SvgIcon } from '@loophq/ui';
import NavigationHeaderMiniNav from '@/components/layout/MiniNav/NavigationHeaderMiniNav.vue';
import NavigationHeaderSearch from '@/components/layout/NavigationHeaderSearch.vue';

const emit = defineEmits(['menu-click', 'update-size']);

const menuActive = ref(false);

onMounted(() => {
  emit('update-size');
});

const menuHandler = () => {
  emit('menu-click');
  menuActive.value = !menuActive.value;
};

</script>

<style lang="scss">
.navigation-header {
  $parent: &;

  display: flex;
  align-items: center;
  padding: 0 var(--spacing-24);
  z-index: 1000;

  &__menu-button {
    flex-shrink: 0;
    display: none;

    & svg {
      color: white;
    }
  }

  &__main {
    flex-grow: 1;
  }

  &__user-wrap#{$parent}__user-wrap {
    width: auto !important;
    height: 100%;
    flex-shrink: 0;
    display: flex;
  }
}

@media screen and (width <= 960px) {
  .navigation-header {
    $parent: &;

    &__menu-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__logo {
      display: none;
    }
  }
}
</style>
