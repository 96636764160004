import _ from 'lodash';
import axios from 'axios';

const returns = function (filterSettings = {}) {
  // This is what the endpoint expects
  /* const filterRequestTemplate = {
        search: '',
        type: [],
        status: [],
        label_status: [],
        return_method: [],
        order_by: '',
        sort: '',
        per_page: '',
        page: '',
    } */

  this.request = {
    ...filterSettings
  };

  this.cancelSource = null;
};

returns.prototype = {
  updateExisting(existingItems, newItems) {
    let updated = _.cloneDeep(existingItems);

    newItems.forEach(item => {
      // Check if we already have this saved in our list
      const existing = updated.some(upd => upd.id === item.id);

      if (existing) {
        updated = updated.map(upd => {
          if (upd.id === item.id) {
            return item;
          }
          return upd;
        });
      } else {
        // Add on the the end if we didn't have it before
        updated = [
          ...updated,
          item
        ];
      }
    });
    return updated;
  },
  get(id) {
    return new Promise((resolve, reject) => {
      if (!id) {
        console.error('API: returns.get() requires an id as an input.');
        reject();
      }

      axios.get(`returns/${id}`)
        .then(res => {
          if (res.data.errors) reject(res.data.errors);
          else resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  /** @deprecated Use filter instead **/getList(filters = {}) {
    // We have a request out, go ahead and cancel it in favor of this one
    if (this.cancelSource) {
      this.cancelSource.cancel('Canceled');
    }

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const endpoint = filters.status && filters.status.includes('in_review') ? 'in-review' : 'filter';

      // Get a new cancel token
      const CancelToken = axios.CancelToken;
      this.cancelSource = CancelToken.source();

      try {
        const res = await axios
          .post(`returns/${endpoint}`, {
            ...this.request,
            ...filters
          }, {
            cancelToken: this.cancelSource.token
          });

        // Reset cancel token now that our request is done
        this.cancelSource = null;
        resolve(res);
      } catch (err) {
        // Ignore promise canceled errors, they're not really errors per se
        if (err.message !== 'Canceled') {
          reject(err);
        }
      }
    });
  },
  filter(filters = {}) {
    // We have a request out, go ahead and cancel it in favor of this one
    if (this.cancelSource) {
      this.cancelSource.cancel('Canceled');
    }

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const CancelToken = axios.CancelToken;
      this.cancelSource = CancelToken.source();

      try {
        const res = await axios
          .post(`returns/filter`, {
            ...this.request,
            ...filters
          }, {
            cancelToken: this.cancelSource.token
          });

        // Reset cancel token now that our request is done
        this.cancelSource = null;
        resolve(res);
      } catch (err) {
        // Ignore promise canceled errors, they're not really errors per se
        if (err.message !== 'Canceled') {
          reject(err);
        }
      }
    });
  },
  getPage() {
    return axios.get(`returns`);
  },
  cancel(id) {
    return new Promise((resolve, reject) => {
      if (!id || typeof id !== 'string') {
        console.error('API: returns.cancel() requires an id as an input.');
        reject();
      }

      axios.post(`returns/${id}/cancel`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  close(id) {
    return new Promise((resolve, reject) => {
      if (!id || typeof id !== 'string') {
        console.error('API: returns.close() requires an id as an input.');
        reject();
      }

      axios.post(`returns/${id}/close`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  process(id, restockItems) {
    if (!id) {
      console.error('API: returns.process() requires an id as an input.');
      Promise.reject();
    }

    return axios
      .post(`returns/${id}/process`, restockItems)
      .then(res => {
        return res.data;
      });
  },
  async rejectLineItems(returnId, lineItemIds) {
    if (!returnId || !lineItemIds) {
      console.error('API: returns.rejectLineItem() requires both a returnId and  lineItemIds as an input.');
      Promise.reject();
    }

    return await axios.post(`returns/${returnId}/reject-item`, {
      line_item_ids: lineItemIds
    });
  },
  async rejectReturn(returnId) {
    if (!returnId) {
      console.error('API: returns.rejectLineItem() requires a returnId as an input.');
      Promise.reject();
    }

    return await axios.post(`returns/${returnId}/reject`);
  },
  async sendRejectNotification(returnId) {
    if (!returnId) {
      console.error('API: returns.sendRejectNotification() requires a returnId as an input.');
      Promise.reject();
    }

    return await axios.post(`returns/${returnId}/reject-notification`);
  },
  async comments(returnId, comment, lineId = null) {
    if (!returnId || !comment) {
      console.error('API: returns.comments() requires both a returnId and comment as inputs.');
      Promise.reject();
    }

    return await axios.post(`returns/${returnId}/comments`, {
      line_item_id: lineId,
      comment
    });
  },
  async acceptReturn(returnId) {
    if (!returnId) {
      console.error('API: returns.acceptReturn() requires a returnId as an input.');
      Promise.reject();
    }

    return await axios.get(`returns/${returnId}/accept`);
  },
  updateCustomer(id, payload) {
    return new Promise((resolve, reject) => {
      if (!id || typeof id !== 'string') {
        console.error('API: returns.updateCustomer() requires an id and a payload as an input.');
        reject();
      }

      axios.post(`returns/${id}/customer`, payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  flagReturnForManualReview(id, payload) {
    return new Promise((resolve, reject) => {
      if (!id || typeof id !== 'string') {
        console.error('API: returns.flagReturnForManualReview() requires an id and a payload as an input.');
        reject();
      }

      axios.post(`returns/${id}/flag-return`, payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
     *
     * @param {number} id - A return id
     * @param {object} payload - An address object
     * @param {string} payload.name
     * @param {string} payload.phone
     * @param {string} payload.address1
     * @param {string} payload.address2
     * @param {string} payload.country
     * @param {string} payload.city
     * @param {string} payload.state
     * @param {string} payload.country
     * @param {string} payload.zip
     * @param {string} payload.company
     */
  updateAddress(id, payload) {
    if (!id) {
      console.error('API: returns.updateAddress() requires an id and a payload as an input.');
      return Promise.reject();
    }

    return axios.post(`returns/${id}/address`, {
      phone: payload.phone,
      shipping_address: payload.address1,
      shipping_address_2: payload.address2,
      shipping_city: payload.city,
      shipping_country: payload.country,
      shipping_name: payload.name,
      shipping_state: payload.state,
      shipping_zip: payload.zip,
      shipping_country_code: payload.countryCode,
      shipping_company: payload.company,
    });
  },
  notes: {
    add(id, note) {
      return new Promise((resolve, reject) => {
        if (!id || !note || typeof note !== 'object') {
          console.error('API: returns.notes.add() requires an id and a note as an input.');
          reject();
        }

        axios.post(`returns/${id}/notes/create`, note)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    remove(id, noteId) {
      return new Promise((resolve, reject) => {
        if (!id || !noteId) {
          console.error('API: returns.notes.remove() requires an id and a noteId as an input.');
          reject();
        }

        axios.delete(`returns/${id}/notes/${noteId}/delete`)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
  label: {
    add(id) {
      return new Promise((resolve, reject) => {
        if (!id || typeof id !== 'string') {
          console.error('API: returns.label.add() requires an id and a note as an input.');
          reject();
        }

        axios.get(`returns/${id}/label/create`)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    regenerate(id) {
      return new Promise((resolve, reject) => {
        if (!id) {
          console.error('API: returns.label.regenerate() requires an id as an input.');
          reject();
        }

        axios.get(`returns/${id}/label/regenerate`)
          .then(resolve)
          .catch(reject);
      });
    },
    send(id) {
      return new Promise((resolve, reject) => {
        if (!id || typeof id !== 'string') {
          console.error('API: returns.label.send() requires an id as an input.');
          reject();
        }

        axios.get(`returns/${id}/label/send`)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    remove(id) {
      return new Promise((resolve, reject) => {
        if (!id || typeof id !== 'string') {
          console.error('API: returns.label.remove() requires an id as an input.');
          reject();
        }

        axios.get(`returns/${id}/label/remove`)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
  async restock(id, items) {
    if (!id || !items || !Array.isArray(items) || !items.length) {
      console.error('API: returns.remove() requires an id and an array of line items as an input.');
      return Promise.reject();
    }

    // The API just accepts an array of ids
    const itemIds = items
      .filter(item => item.restock && item.restocked !== 1)
      .map(item => item.id);
    const res = await axios.post(`returns/${id}/restock`, itemIds);

    return Promise.resolve({
      restocked: res.data,
      items: items.map(item => {
        if (itemIds.includes(item.id)) {
          return {
            ...item,
            restocked: res.data ? 1 : null // We're on a number based restocked system here
          };
        }

        return item;
      })
    });
  },
  async getEvents() {
    return axios.get(`/return-events`)
      .then((res) => res.data);
  },
  transactions: {
    release(id, tid) {
      return axios.post(`/returns/${id}/transaction/${tid}/release`)
        .then((res) => res.data);
    },
    charge(id, tid, orderLineItemIds) {
      return axios.post(`/returns/${id}/transaction/${tid}/charge`, { orderLineItemIds })
        .then((res) => res.data);
    },
    refund(id, tid, orderLineItemIds) {
      return axios.post(`/returns/${id}/transaction/${tid}/refund`, { orderLineItemIds })
        .then((res) => res.data);
    },
  },
  edit: {
    initialize(id) {
      return axios.get(`/returns/${id}/edit`)
        .then(res => res.data);
    },
    update(id, sessionId, intent, request) {
      return axios.post(`/returns/${id}/edit`, { intent, sessionId, request })
        .then(res => res.data);
    },
    store(id, sessionId, state, breakdown) {
      return axios.post(`/returns/${id}/edit/store`, { sessionId, state, breakdown })
        .then(res => res.data);
    },
    destroy(id, sessionId) {
      return axios.post(`/returns/${id}/edit/destroy`, { sessionId })
        .then(res => res.data);
    },
  },
  handleCancelPickup(returnId) {
    return axios
      .get(`returns/${returnId}/return-method/cancel-pickup`)
      .then(res => {
        return res.data;
      })
      .catch(status => {
        console.error(status);
      });
  },
  handleCreateLabel(returnId) {
    return axios
      .get(`returns/${returnId}/label/create`)
      .then(res => {
        return res.data;
      })
      .catch(status => {
        console.error(status);
      });
  },
};

export default returns;
